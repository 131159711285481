<template lang="pug">
#user-info.step-component
  h2 Please enter your info

  .form-field(:class="{ 'field-error': $v.form.fullName.$error }")
    label Full Name
    input.input(type="text", v-model.trim="form.fullName", placeholder="Type your name")
    .form-error(v-if="$v.form.fullName.$error") This field is required

  .form-field(:class="{ 'field-error': $v.form.email.$error }")
    label(for="email") Email Address
    input.input(type="email", name="email", v-model.trim="form.email")
    .form-error(v-if="$v.form.email.$error") Please enter a valid email address

  .form-field(:class="{ 'field-error': $v.form.phone.$error }")
    label Phone Number
    input.input(
      type="tel",
      v-mask="'1 (NNN) NNN-NNNN'",
      v-model="form.phone",
      placeholder="1 (555) 555-5555"
    )
    .form-error(v-if="$v.form.phone.$error") This field is required

  .form-field(:class="{ 'field-error': $v.form.gender.$error }")
    label(for="gender") Sex
    select#gender(name="gender", v-model="form.gender")
      option(value="Male") Male
      option(value="Female") Female
    .form-error(v-if="$v.form.gender.$error") Please select an option from the list

  .form-field(:class="{ 'field-error': $v.form.height.$error }")
    label(for="height") Height
    input.input(
      name="height",
      type="text",
      v-mask="heightMask",
      v-model="form.height",
      placeholder="5'5\""
    )
    .form-error(v-if="!$v.form.height.required") This field is required
    .form-error(v-if="!$v.form.height.maxInches") Inches cannot be greater than 11

  .form-field(:class="{ 'field-error': $v.form.lastMeal.$error }")
    label(for="lastmeal") Hours Since Last Meal
    input(type="number", name="lastmeal", min="1", max="99", step="1", v-model="form.lastMeal")
    .input-suffix {{ hoursSuffix }}
    .form-error(v-if="$v.form.lastMeal.$error") This field is required

  VerificationButton.button(
    @click.native="submit()",
    text="Confirm",
    :state="verificationButtonState"
  )
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  props: {},

  data() {
    return {
      form: {
        fullName: '',
        phone: '',
        gender: '',
        height: '',
        email: '',
        lastMeal: '',
      },
      heightMask: '#\'##?"',
      verificationButtonState: 'init',
    }
  },

  validations: {
    form: {
      fullName: { required },
      phone: { required },
      gender: { required },
      email: { required, email, minLength: minLength(2) },
      height: {
        required,
        maxInches,
      },
      lastMeal: { required },
    },
  },

  computed: {
    hoursSuffix,
  },

  watch: {},

  methods: {
    submit,
  },

  components: {
    VerificationButton: require('@/components/VerificationButton').default,
  },
}

/* Computed ---------------------------------------------------- */
function hoursSuffix() {
  return this.form.lastMeal == '' ? '' : this.form.lastMeal <= 1 ? 'hour' : 'hours'
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function submit() {
  this.$v.$touch()
  if (!this.$v.$invalid) {
    this.verificationButtonState = 'try'
    this.$store.dispatch('user/create', {
      userInfo: { deviceData: navigator?.userAgent, ...this.form },
    })
    this.verificationButtonState = 'success'

    setTimeout(() => {
      this.$emit('complete')
    }, 1000)
  }
}
function maxInches(value) {
  const pieces = value.split("'")
  if (pieces[1]?.length === 2 && +pieces[1] > 11) {
    this.form.height = ''
    return false
  }
  return true
}
</script>
